import { i18n } from '@/i18n';
import axios from 'axios';
import SecondFactorDto from '../dtos/SecondFactorDto';
import SecondFactorClientMock from './mocks/SecondFactorClientMock';

class SecondFactorClient {
	private readonly RESOURCE_SECOND_FACTORS = 'SecondFactors';

	public async authenticate(id: string, recaptchaToken: string, secondaryEmailChange?: boolean): Promise<void> {
		return axios.post(`${this.RESOURCE_SECOND_FACTORS}/${id}/authenticate?recaptchaToken=${recaptchaToken}`, secondaryEmailChange, 
			{ headers:{ 'Content-Type': 'application/json'}, errorMessage: i18n.global.t("toast.messages.errorSendingVerificationCode") });
	}
}

//export default process.env.NODE_ENV === 'production' ? SecondFactorClient : SecondFactorClientMock
export default SecondFactorClient